import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { screenSize } from '../tokens'

export default function MobileOnly({
  children,
  style,
}: {
  // @ts-ignore: JSX type error
  children: JSX.Element | JSX.Element[] | string
  style?: any
}) {
  return <Container style={style}>{children}</Container>
}

const Container = styled.div`
  display: none;
  ${screenSize.mobile(css`
    display: block;
  `)}
`
